// FIXME - copied from support flow for now - needs to be de-duplicated
export const FEATURED_EBOOK = 'ebook';
export const FEATURED_GUIDE = 'guide';
export const FEATURED_TEMPLATE = 'template';

export const PHONE_NUM = '(866) 351-3457';
export const PHONE_NUM_RAW = '8663513457';

export const BROKER_PHONE_NUM = '(866) 351-3452';
export const BROKER_PHONE_NUM_RAW = '8663513452';

export const ALEGEUS_PHONE_NUM = '(866) 609-4655';
export const ALEGEUS_PHONE_NUM_RAW = '8666094655';

export const ALIST = 'ALIST';
export const BLIST = 'BLIST';
export const CLIST = 'CLIST';
export const DLIST = 'DLIST';
export const ELIST = 'ELIST';

export const ABTYPE = ['ALIST', 'BLIST'];
export const CDTYPE = ['CLIST', 'DLIST'];
export const ETYPE = ['ELIST'];

export const METHOD_ALEGEUS = 'alegeus';
export const METHOD_CHAT = 'chat';
export const METHOD_EMAIL = 'email';
export const METHOD_PHONE = 'phone';
export const METHOD_HR_ADMIN = 'hr';
export const METHOD_BROKER_ADMIN = 'broker';
export const METHOD_HELP_CENTER = 'help_center';
export const METHOD_TRAINING = 'training';
export const METHOD_BLOG = 'blog';
export const METHOD_QUICKSTART_CHAT = 'qsChat';
export const METHOD_QUICKSTART_EMAIL = 'qsEmail';

export const WINDOW_WIDTH_BREAKPOINT = 700;

export const IMPLEMENTATION_CONTACT_OPTIONS = [METHOD_QUICKSTART_CHAT, METHOD_QUICKSTART_EMAIL];

export const JAZZ_HR_EMAIL = 'zenefits-support@jazzhr.com';
export const TRINET_PHONE_NUM = '(800) 233-9574';

export const IMPLEMENTATION_CONTACT_METHODS = {
  qsChat: {
    description: 'Start chatting with an Quickstart expert.',
    subDescription: 'Monday - Friday 8 AM - 4 PM MST',
    link: 'Chat with us',
    method: 'Chat',
    disableText: 'We are currently outside our designated business hours.',
    nonEmergencyText: 'Chat support is not currently available. We apologize for the inconvenience',
  },
  qsEmail: {
    description: 'Get an emailed response from our Quickstart team.',
    link: 'Email us',
    method: 'Email',
  },
};

export const DISABLE_CONTACT_BANNERS = ['emergency', 'july4Holiday', 'jan1Holiday'];
